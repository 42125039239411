<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="stockamounts" ref="dt" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id" :tableStyle="styleHeight"
                    :scrollHeight="sHeight" :scrollable="true" :virtualScrollerOptions="{ itemSize: 40 }"
                    responsiveLayout="scroll" :loading="loading" :paginator="false" :lazy="false" class="p-mt-3">
                    <template #header>
                        <div class="grid">
                            <div class="col">
                                <label :for="allStoreMoneyInput">
                                    库存总额:
                                </label>
                                <InputText id="allStoreMoneyInput" type="text" v-model="allStoreMoney" readonly />
                            </div>
                            <div class="col">
                                <label :for="currencyInput">
                                    币种:
                                </label>
                                <InputText id="currencyInput" type="text" :value="currencyName" readonly />
                            </div>
                            <div class="col">
                                <label :for="exchangeRateInput">
                                    汇率:
                                </label>
                                <InputText id="exchangeRateInput" @input="exchangeRateChange" type="text"
                                    v-model="exchangeRate" />
                            </div>
                            <div class="col">
                                <label :for="rmbMoneyInput">
                                    人民币金额:
                                </label>
                                <InputText id="rmbMoneyInput" type="text" v-model="allStoreRMBMoney" readonly />
                            </div>
                            <div class="col-3">
                                <div class="text-right">
                                    <Button type="button" icon="pi pi-external-link" label="导出" @click="exportData()"
                                        class="p-button-outlined mb-2" />&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button type="button" icon="pi pi-filter-slash" label="Clear"
                                        class="p-button-outlined mb-2" @click="clearFilter1()" />
                                </div>
                            </div>
                        </div>
                    </template>
                    <Column field="signingdate" :showFilterOperator="true" header="签约日期" frozen
                        :filterMatchModeOptions="matchModes" :sortable="false">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="shipmentno" :showFilterOperator="true" header="出运单号" :sortable="false"
                        :filterMatchModeOptions="matchModes" frozen>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="sku" :showFilterOperator="true" :filterMatchModeOptions="matchModes" header="SKU"
                        :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="mpn" :showFilterOperator="true" :filterMatchModeOptions="matchModes" header="MPN"
                        :sortable="true">
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();"
                                class="p-button-success"></Button>
                        </template>
                    </Column>
                    <Column field="fobprice" header="单价" :sortable="true">
                    </Column>
                    <Column field="fobcurrencyname" header="币种" :sortable="true">
                    </Column>
                    <Column field="shipquantity" header="在途数量" :sortable="true">
                    </Column>
                    <Column field="shipmoney" header="在途库存金额" :sortable="true">
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
</template>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import commonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '783';
            return {
                confirm,
                currentMenuId,
            };
        },
        data() {
            return {
                matchModes: [],
                timeMatchModes: [],
                numMatchModes: [],
                butLoading: false,
                position: 'bottomleft',
                filters1: null,
                selectedRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                listTitle: '在途库存金额统计',
                selectedKey: {
                    783: true
                },
                expandedKey: null,
                stockamounts: [],
                loading: false,
                nodes: [],
                items: [{
                    label: '统计操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                        label: '导出',
                        icon: 'pi pi-fw pi-user-plus',
                        command: () => {
                            this.exportData();
                        }
                    }, ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                allStoreMoney: 0,
                currencyName: '',
                exchangeRate: 1,
                allStoreRMBMoney: 0,
            }
        },
        created() {
            MessageTip.initMsg();
            this.initFilters1();
            this.loadMenuTree();
            this.sHeight = (window.innerHeight - 280) + "px";
            this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
            this.minHeight = "height:" + (window.innerHeight - 132) + "px";
        },
        methods: {
            initFilters1() {
                this.timeMatchModes = [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ];
                this.numMatchModes = [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ];
                this.matchModes = [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ];
                this.filters1 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'signingdate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'shipmentno': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'sku': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    }
                }
            },
            clearFilter1() {
                this.initFilters1();
            },
            loadMenuTree() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '731',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usertreemenulist(where:" + JSON.stringify(listwhere) +
                    "){key label data nodetype icon leaf children {key label data nodetype parentid parenturl icon leaf} }}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.nodes = jsonData.data.usertreemenulist;
                        if (this.$route.query.nodeid && this.$route.query.nodeid != '' && this.nodes) {
                            this.selectedKey = {};
                            this.selectedKey[this.$route.query.nodeid] = true;
                            this.expandedKey = {};
                            this.expandedKey[this.currentMenuId] = true;
                            var isFind = false;
                            for (var i = 0; i < this.nodes.length; i++) {
                                for (var j = 0; j < this.nodes[i].children.length; j++) {
                                    if (this.nodes[i].children[j].key == this.$route.query.nodeid) {
                                        isFind = true;
                                        this.queryPlanObj = {
                                            name: this.nodes[i].children[j].label,
                                            id: this.nodes[i].children[j].key,
                                            parentid: this.currentMenuId,
                                            queryconfig: this.nodes[i].children[j].data,
                                        };
                                        if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig !=
                                            '') {
                                            this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                        }
                                        this.listTitle = this.nodes[i].children[j].label;
                                        break;
                                    }
                                }
                                if (isFind) {
                                    break;
                                }
                            }
                        }
                        this.loadData(1);
                    }
                });
            },
            onNodeSelect(node) {
                console.log(node);
                setTimeout(() => {
                    var currentKey = '';
                    if (this.selectedKey) {
                        for (var key in this.selectedKey) {
                            currentKey = key;
                            break;
                        }
                    }
                    if (node.nodetype == '1') {
                        this.queryPlanObj = {
                            name: '',
                            id: '-1',
                            parentid: this.currentMenuId,
                            queryconfig: '',
                        };
                        console.log('node.key:' + node.key);
                        console.log('selectedKey:');
                        console.log(this.selectedKey);
                        console.log('currentKey:' + currentKey);
                        if (node.key != this.currentMenuId) {
                            window.location.href = node.data;
                        } else {
                            this.clearFilter1();
                            this.clearFilter2();
                            this.loadData(1);
                            this.listTitle = "在途库存金额统计";
                        }
                    }
                    if (node.nodetype == '2') {
                        if (this.currentMenuId != node.parentid) {
                            window.location.href = node.parenturl + "?nodeid=" + node.key;
                        } else {
                            this.queryPlanObj = {
                                name: node.label,
                                id: node.key,
                                parentid: this.currentMenuId,
                                queryconfig: node.data,
                            };
                            console.log('expandedKey:');
                            console.log(this.expandedKey);
                            if (this.queryPlanObj.queryconfig && this.queryPlanObj.queryconfig != '') {
                                console.log('queryconfig:');
                                console.log(this.queryPlanObj.queryconfig);
                                this.filters1 = JSON.parse(this.queryPlanObj.queryconfig);
                                this.loadData(1);
                                this.listTitle = node.label;
                            }
                        }
                    }
                }, 20);
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            loadData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: -1,
                    conditions: []
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{shipamountlist(where:" + JSON.stringify(listwhere) +
                    "){id shipmentno mpn sku fobprice fobcurrencyname exchangerate shipquantity shipmoney storeid signingdate }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.stockamounts = jsonData.data.shipamountlist;
                        this.allStoreMoney = 0;
                        this.currencyName = '';
                        for (var i = 0; i < this.stockamounts.length; i++) {
                            if (i == 0) {
                                this.currencyName = this.stockamounts[i]['fobcurrencyname'];
                                this.exchangeRate = Number(this.stockamounts[i]['exchangerate']);
                            }
                            var tempnum = Number(this.stockamounts[i]['fobprice']);
                            if (!isNaN(tempnum)) {
                                this.allStoreMoney = this.allStoreMoney + tempnum;
                            }
                        }
                        if (this.allStoreMoney > 0)
                            this.allStoreMoney = commonJs.moneySwitch(this.allStoreMoney, 2)
                        this.calculateallStoreMoney();
                    }
                });
            },
            calculateallStoreMoney() {
                console.log('calculateallStoreMoney...');
                var exchangeRateVal = Number(this.exchangeRate);
                console.log(exchangeRateVal);
                var allStoreMoneyVal = Number(this.allStoreMoney);
                console.log(allStoreMoneyVal);
                if (!isNaN(exchangeRateVal) && !isNaN(allStoreMoneyVal)) {
                    this.allStoreRMBMoney = commonJs.moneySwitch(allStoreMoneyVal * exchangeRateVal, 2);
                }
            },
            exchangeRateChange() {
                setTimeout(() => {
                    this.calculateallStoreMoney();
                }, 50);
            },
            closeMaximizable() {},
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            exportData() {
                this.$refs.dt.exportCSV();
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
        },
        components: {
            'AppFooter': AppFooter,
        }
    }
</script>